/* Page Title */

.title {
    text-align: center;
    font-size: 4em;
    font-family: 'Permanent Marker';
    margin: 0px;
    padding: 0px;
}

/* Section Title */

.subtitle {
    text-align: center;
    font-size: 2em;
    font-family: 'Kalam';
    margin: 0px;
    padding: 0px 30px 0px 30px;
}

h4 {
    font-family: 'Courgette';
    font-size: 18px;
    text-align: center;
}

h6 {
    font-weight: 300;
    font-size: 16px;
    margin: 10px;
}

/* Intro Styling*/

.introCap {
    background-color: white;
    margin: 20px 0px 20px 0px;
    padding: 15px 15px 5px 15px;
    border-radius: 5px;
}

.context {
    text-align: center; 
    font-size: 12px; 
    display:inline-block;
    margin: 0px 5px 0px 5px;
    padding: 0px;
    color: #444;
}

.inlineHR {
    display: inline-block; 
    width: 33%; 
    position: relative; 
    top: 0.5em;
    height: 0px;
    margin: 0px 0px 30px 0px;
    padding: 0px;
    background-color: darkgrey;
}

/* Conclusion Styling*/

.conclusionGrid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 0.3fr 0.3fr;
    grid-template-rows: 1fr;
    margin-top: 20px;
}

.lessons {
    grid-column: 1;
    grid-row: 1;
}

.softSkills {
    grid-column: 2;
    grid-row: 1;
}

.hardSkills {
    grid-column: 3;
    grid-row: 1;
}

.skillsList {
    margin-left: -25px;
    font-weight: lighter;
}

/* General Page Styling */

.centered {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.centeredRow, .centeredRowPermanent {
    display: flex;
    justify-content: center;
}

.longShadow {
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072);
}

.smallShadow {
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034);
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.whiteDiv {
    background-color: white;
    border-radius: 5px;
    padding: 30px;
}

.longHR {
    width: 80%;
    border: 0;
    height: 3px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

/* General Page Styling - Text */

.largePara {
    text-align: center;
    width: 70%;
}

.smallPara {
    text-align: center;
    width: 40%;
}

.smallText {
    font-size: 12px;
}

.centeredText {
    text-align: center;
}

.noto {
    font-family: 'Noto Sans';
}

.kalam {
    font-family: 'kalam';
}

/* Sizing shortcuts */

.thirty {
    width: 30%;
}

.forty {
    width: 40%;
}

.fifty {
    width: 50%;
}

.sixty {
    width: 60%;
}

.seventy {
    width: 70%;
}

.eighty {
    width: 80%;
}

/* Video-Specific */

.smallVideoDiv {
    width: 24%;
}

.largeVideoDiv {
    width: 76%;
}


/* styling adjusts to smaller page sizes*/
@media only screen and (max-width: 600px) {
    
    .conclusionGrid{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0.3fr 0.3fr;
    }

    .lessons {
        grid-column: 1;
        grid-row: 1;
    }
    
    .softSkills {
        grid-column: 1;
        grid-row: 2;
        margin-bottom: -15px;
    }
    
    .hardSkills {
        grid-column: 1;
        grid-row: 3;
    }

    .title {
        font-size: 2.5em;
    }

    .smallPara {
        width: 70%;
    }

    .thirty, .smallVideoDiv, .largeVideoDiv {
        width: 100%;
    }

    .forty, .fifty, .sixty, .seventy {
        width: 80%;
    }

    .whiteDiv {
        padding: 10px;
    }

    .skillsList {
        margin-left: 10px;
    }

    ul {
        padding-left: 20px;
    }

    h4 {
        margin: 10px 0px 10px 0px;
    }

    .centeredRow {
        align-items: center;
        flex-direction: column;
    }
}