/** General styling for app **/
body {
  --main-color: #adc0cf;
  --bg-color: #EEF2F7;
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.portfolio {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: -15px;
}

.postList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
  margin-top: -30px;
}

h1 { /** Main Title **/
  text-align: center;
  font-family: 'Raleway';
  font-weight: '700';
  margin-top: 30px;
  color: black;
}
h3 { /** Post Title **/
  font-weight: 700;
}

/** Header **/

header {
  display: flex;
  flex-direction: row;
  margin: 0px 30px 0px 0px;
  justify-content: space-between;
  align-items: center;
}

.headerDiv {
  width: 25%;
  justify-content: center;
  position: relative;
}

nav {
  display: flex;
  padding-right: 40px;
}

.a {
  text-decoration: none;
}

.navLink {
  text-decoration: none;
  color: grey;
  padding-left: 25px;
}

.navLink:hover {
  color: black;
}

.bolt {
  width: 100px;
  padding: 45px 0px 0px 40px;
}

.headerText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 0px 20px;
}

.headerTitle {
  font-size: 2em;
  font-family: 'Permanent Marker';
}

/** SideBar **/

.sideBar {
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 30px;
  position: relative;
}

.menu {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  row-gap: 20px;
  padding-top: 10px;
}

.dropdown {
  width: 190px;
  padding-bottom: 20px;
}

.aboutBlurb {
  width: 80%;
  margin: 0px 0px 10px 0px;
}

.about2 {
  padding-top: 10px;
}

.brush {
  margin-top: -20px;
}

/** About Page**/

.aboutDiv {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.aboutP {
  margin: 5px 0px 5px 0px;
}

.about {
  margin-left: 20px;
}

.portrait {
  width: 300px;
  margin: 15px 15px 0px 0px;
  border-radius: 5px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
}

/** Individual Posts **/

.post {
  background-color: white;
  margin: 0px 7px 15px 7px;
  padding: 10px;
  border-radius: 5px;
  width: 28%;
  position: relative;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
  align-self: center;
}

.photo {
  width: 100%;
  border-radius: 3px;
}

.description {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px;
  flex-direction: column;
  color: black;
}

.postTitle {
  line-height: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.postText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 20px 0px 20px;
}

.subjText, .formText {
  border: 1px solid black;
  border-radius: 4px;
  padding: 1px 10px 4px 10px;
  margin: 8px 8px 0px 0px;
  display: inline-block;
  line-height: 20px;
}

.descText {
  margin-top: 10px;
}

.postTitle {
  margin-bottom: 10px;
}

.post:hover .postText {
  background-color: rgba(256, 256, 256, 0.7);
  transition: 0.3s;
  cursor: pointer;
}

.post:hover .price {
  color: black
}

.hilight {
  background: linear-gradient(to top, transparent 10%, white 10%, white 50%, transparent 50%);
  padding: 0px 7px 0px 7px;
}

/** transition effects **/

.transition-enter {
  opacity: 0;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/** Resume **/

.resume {
  margin-left: 20%;
  margin-right: 20%;
}

.resumePage {
  border-radius: 5px;
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072);
}

/** Screen size **/ 

@media only screen and (max-width: 1245px) { 
  .postTitle {
    line-height: 20px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1145px) {
  .descText {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: small;
  }
  .subjText, .formText {
    font-size: small;
    line-height: 10px;
  }
}
@media only screen and (max-width: 910px) { /** goes to 2/row **/
  .postTitle {
    margin: 15px 0px 0px 0px;
  }
  .post {
    width: 40%;
  }
  .aboutBlurb {
    width: 70%;
  }
  .dropdown {
    width: 70%;
  }
  nav {
    padding-right: 5%;
  }
}
@media only screen and (max-width: 570px) { /** goes to 1/row **/
  .postList {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 20px;
  }
  .post {
    width: 80%;
  }
  .portfolio {
    flex-direction: column;
    align-items: center;
  }
  .sideBar {
    width: 100%;
    margin-left: 0;
  }
  .aboutBlurb {
    width: 70%;
    text-align: center
  }
  .dropdown {
    width: 24%;
  }
  .menu {
    flex-direction: row;
    column-gap: 20px;
    grid-column-gap: 20px;
  }
  .descText {
    font-size: medium;
  }
  .postTitle {
    font-size: x-large;
    margin: 20px 0px 5px 0px;
    line-height: 25px;
  }
  .subjText, .formText {
    font-size: medium;
    line-height: 20px;
  }
  nav {
    padding-right: 5%;
  }
  header {
    margin: -15px 30px 15px 10px;
  } 
  .aboutDiv {
    flex-direction: column;
  }
  .about {
    margin-left: 0px;
  }
  .portrait {
    width: 380px;
  }
  .resume {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media only screen and (max-width: 400px) {
  nav {
    padding-right: 2%;
  }
  .navLink {
    padding-left: 10px;
  }
  .portrait {
    width: 300px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;1,500&display=swap');

/* permanent-marker-regular - latin */
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/permanent-marker-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Permanent Marker Regular'), local('PermanentMarker-Regular'),
      url('fonts/permanent-marker-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('fonts/permanent-marker-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('fonts/permanent-marker-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('fonts/permanent-marker-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('fonts/permanent-marker-v10-latin-regular.svg#PermanentMarker') format('svg'); /* Legacy iOS */
}


/* courgette-regular - latin */
@font-face {
  font-family: 'Courgette';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/courgette-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Courgette Regular'), local('Courgette-Regular'),
      url('fonts/courgette-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('fonts/courgette-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('fonts/courgette-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('fonts/courgette-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('fonts/courgette-v8-latin-regular.svg#Courgette') format('svg'); /* Legacy iOS */
}

/* kalam-regular - latin */
@font-face {
  font-family: 'Kalam';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/kalam-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Kalam'), local('Kalam-Regular'),
      url('fonts/kalam-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('fonts/kalam-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('fonts/kalam-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('fonts/kalam-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('fonts/kalam-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('fonts/kalam-v11-latin-regular.svg#Kalam') format('svg'); /* Legacy iOS */
}

/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/noto-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans'), local('NotoSans'),
      url('fonts/noto-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('fonts/noto-sans-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('fonts/noto-sans-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('./fonts/noto-sans-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('./fonts/noto-sans-v11-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}